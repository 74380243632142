<template>
    <div style="background: linear-gradient(225deg, #812CC8 0%, #E60B8E 47.40%, #FFE600 100%);">
        <div>
            <headerComponent></headerComponent>
        </div>
        <div class="thankYouHeaderGrid">
            <div class="thankYou_headerGridRow_1">
                <svg xmlns="http://www.w3.org/2000/svg" width="148" height="132" viewBox="0 0 148 132" fill="none">
                <g filter="url(#filter0_d_672_13176)">
                    <path d="M80 16.0002C80 15.6002 112 15.8335 128 16.0002V64.0001H112V80.0001H96V96.0001H80V112H64V96.0001H48V80.0001H32V64.0001H16V16.0002H64V32.0001H80V16.0002Z" fill="url(#paint0_linear_672_13176)"/>
                    <rect y="16" width="16" height="48" fill="#02090B"/>
                    <rect x="16" width="48" height="16" fill="#02090B"/>
                    <rect x="128" y="16" width="16" height="48" fill="#02090B"/>
                    <rect x="80" width="48" height="16" fill="#02090B"/>
                    <rect x="64" y="16" width="16" height="16" fill="#02090B"/>
                    <rect x="64" y="112" width="16" height="16" fill="#02090B"/>
                    <rect x="80" y="96" width="16" height="16" fill="#02090B"/>
                    <rect x="96" y="80" width="16" height="16" fill="#02090B"/>
                    <rect x="112" y="64" width="16" height="16" fill="#02090B"/>
                    <rect x="32" y="80" width="16" height="16" fill="#02090B"/>
                    <rect x="16" y="64" width="16" height="16" fill="#02090B"/>
                    <rect x="48" y="96" width="16" height="16" fill="#02090B"/>
                </g>
                <defs>
                    <filter id="filter0_d_672_13176" x="0" y="0" width="148" height="132" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dx="4" dy="4"/>
                        <feComposite in2="hardAlpha" operator="out"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_672_13176"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_672_13176" result="shape"/>
                    </filter>
                    <linearGradient id="paint0_linear_672_13176" x1="44" y1="95.0001" x2="127.986" y2="18.7712" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFE600"/>
                        <stop offset="1" stop-color="#FF9900"/>
                    </linearGradient>
                </defs>
            </svg></div>
            <div class="thankYou_headerGridRow_2"><h1 class="tywelcome" style="color: #ffffff;text-align: center">Thank you for your purchase!</h1></div>



        </div>

        <div style="margin-top: 64px;">
            <div class="divider"></div>
            <nextStepsG></nextStepsG>
        </div>
        <div class="tyexploreDomains">
            <h2 style="color: #ffffff">Explore our domain collection</h2>
        </div>
        <div>
            <DomainsGrid></DomainsGrid>
        </div>
        <div>
            <div class="exploreBtn">
                <a href="/domains"><div class="headerJoinBtnMain" style="width: 177px; height: 48px;margin-bottom: 128px;margin-top: 32px" ><p class="headerMoreBtntext">Explore More</p></div></a>
                <!--                    <a href="/join"><div class="headerMoreBtnMain" ><p class="headerJoinBtntext">Discover more</p></div></a>-->
            </div>
        </div>

        <div>
            <mainfooter></mainfooter>
        </div>
    </div>
</template>

<script>
    import headerComponent from "@/components/reusable/header";
    import mainfooter from "@/components/footer";
    import DomainsGrid from "@/components/reusable/DomainsGrid";
    import nextStepsG from "@/components/reusable/nextSteps";

    export default {
        name: "thankYou",
        components: {headerComponent,mainfooter,DomainsGrid,nextStepsG},
        data: () => ({
            show:false,
        }),
        mounted() {
            this.show = true; // might need this.$nextTick
        },

    }
</script>

<style>


</style>


